

















import { Component, Vue } from "vue-property-decorator";
import SinglePerson from "@/components/team/SinglePerson.vue";

@Component({
	components: { SinglePerson },
})
export default class Team extends Vue {
	koch = require("@/assets/Koch.jpg");
	levacher = require("@/assets/Levacher.jpg");
	empty = require("@/assets/emptyTeam.png");
}
